import React, { useState, useEffect } from "react";
import "./App.css";
import sadfrog from "./media/sadfrog.jpg";
import overlayGif from "./media/rain.gif";
import { FaTelegramPlane } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { AiOutlineCopy } from "react-icons/ai";

function App() {
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  const copyToClipboard = () => {
    const address = "5am7Sj4jd5NpAThDnfXkaYmK5MfLTY8SUDYpABSfd3Bs";

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(address)
        .then(() => setCopied(true))
        .catch(() => setCopied(false));

      setTimeout(() => setCopied(false), 2000);
    } else {
      alert(
        "Sorry, copying to clipboard is not supported in your browser. Please copy the address manually."
      );
    }
  };

  return (
    <div className="App">
      {loading ? (
        <div className="loading-text">
          <div className="one">You know what's sadder than being Sad Frog?</div>
          <div className="two">
            Not having <span className="sadFrog">Sad Frog.</span>
          </div>
        </div>
      ) : (
        <div className="content fade-in">
          <nav className="navbar">
            <div className="navbar-left">Sad Frog</div>
          </nav>
          <div className="container">
            <div className="image-container">
              <img src={sadfrog} alt="Sad Frog" className="image-frog" />
              <img src={overlayGif} alt="Overlay GIF" className="overlay-gif" />
            </div>

            <div className="address-container">
              <h1>
                CA: 5am7Sj4jd5NpAThDnfXkaYmK5MfLTY8SUDYpABSfd3Bs
                <AiOutlineCopy
                  className="copy-icon"
                  onClick={copyToClipboard}
                />
              </h1>
            </div>
            {copied && <div className="copied-animation">Copied!</div>}
          </div>

          <a
            href="https://t.me/+YwEDoW8c7984Yzg0"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <FaTelegramPlane className="icon" />
          </a>

          <a
            href="https://twitter.com/sadfrogcrypto"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <RiTwitterXFill className="icon" />
          </a>

          <iframe
            id="dextools-widget"
            title="DEXTools Trading Chart"
            width="90%"
            height="500"
            src="https://www.dextools.io/widget-chart/en/solana/pe-light/Ftnd5mRjgmm2YL4wJUh6JAGyY2xwVWcKEYFgqAZEbL1K?theme=dark&chartType=1&chartResolution=30&drawingToolbars=false&tvPlatformColor=101010&tvPaneColor=1b1b1b&headerColor=1A2421"
            style={{
              marginTop: "4rem",
              marginBottom: "4rem",
              border: "none",
            }}
          ></iframe>
        </div>
      )}
    </div>
  );
}

export default App;
